<template>
  <div id="app">
    <vue-confirm-dialog></vue-confirm-dialog>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",

  components: {},
  data: () => ({}),
};
</script>
<style >
* {
  margin: 0;
  padding: 0;
}

.contenedor-main {
  min-width: 100% !important;
  width: 100%;
  padding-top: 3% !important;
  height: 100% !important;
}
.contenedor-tabla {
  margin: 0;
  padding: 0;
  border: 1px #8884 solid;
  border-top: none;
  min-width: 100% !important;
}

.volverCargar {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

#systable tr {
  text-align: center !important;
}

#systable td {
  font-size: 10px !important;
  font-weight: 500 !important;
}
</style>

