<template>
  <div id="LoginPage">
    <v-app>
      <v-main>
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
              <v-card class="elevation-12">
                <v-toolbar color="primary" dark flat>
                  <v-btn
                    v-if="forgot == true"
                    icon
                    large
                    target="_blank"
                    v-on:click="forgot = false"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>

                  <v-toolbar-title>{{opcion}}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{  }">
                      <v-btn icon large target="_blank" v-on:click="change()">
                        <v-icon>mdi-swap-horizontal</v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                </v-toolbar>
                <v-card-text v-if="forgot == false">
                  <v-form>
                    <v-text-field
                      v-model="user.email"
                      label="Email"
                      prepend-icon="mdi-email"
                      type="text"
                      @keyup.enter="login"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.password"
                      id="code"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      label="Contraseña"
                      prepend-icon="mdi-lock"
                      :type="show ? 'text' : 'password'"
                      @keyup.enter="login"
                      @click:append="show = !show"
                    ></v-text-field>
                    <v-btn
                      color="primary"
                      class="m"
                      v-if="this.opcion == 'Ingresar'"
                      @click="forgotPass()"
                      text
                      plain
                    >
                      <h5>Olvidaste tu contraseña</h5>
                    </v-btn>
                  </v-form>
                </v-card-text>
                <v-card-text v-if="forgot == true">
                  <h5 class="l">Enviar correo para la recuperación de la contraseña</h5>
                  <br />
                  <v-form>
                    <v-text-field
                      v-model="user.email"
                      label="Email"
                      prepend-icon="mdi-email"
                      type="text"
                      @keyup.enter="login"
                    ></v-text-field>
                    <v-btn color="primary" v-if="user.email != null" @click="sendEmail()">
                      <h5>Enviar correo</h5>
                    </v-btn>
                  </v-form>
                </v-card-text>
                <v-card-actions v-if="forgot == false">
                  <v-spacer></v-spacer>
                  <v-btn class="l" v-if="this.opcion == 'Ingresar'" @click="change()" text plain>
                    <h5>No tienes una cuenta? Regístrate</h5>
                  </v-btn>
                  <v-btn class="l" v-if="this.opcion != 'Ingresar'" @click="change()" text>
                    <h5>Inicia sesión</h5>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-btn @click="login()" dark class="mb-2" color="primary">{{entrar}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>


<script>
import firebase from "firebase";
import {  host } from "./Tools/variables";
import axios from "axios";
import router from "../components/Tools/rutas";
// import getUser from '../components/Tools/user'

export default {
  name: "App",
  data() {
    return {
      show: false,
      forgot: false,
      emailSending: false,
      opcion: "Ingresar",
      entrar: "Entrar",
      user: [
        {
          email: "",
          password: ""
        }
      ]
    };
  },
  mounted() {
    document.title = "EDUEXPERT - login";
    // getUser()
    // this.validaUsuario()
    // this.$alert('Error ' + 'No se pudo realizar el registro')
  },
  methods: {
    login() {
      if (this.user.password != null && this.user.email != null) {
        if (this.opcion == "Registrar") {
          firebase
            .auth()
            .createUserWithEmailAndPassword(this.user.email, this.user.password)
            .then(() => {
              firebase
                .auth()
                .currentUser.getIdToken(/* forceRefresh */ true)
                .then(idToken => {
                  const fd = new FormData();
                  fd.append("token", idToken);
                  axios.post(host + "/valida", fd).then(result => {
                    let data = result.data.text;
                    if (data == "OK") {
                      this.validaUsuario(idToken);
                    } else {
                      if (data.text == "Error, dirección IP bloqueada") {
                        this.$alert("IP bloqueada por la administración");
                        firebase.auth().signOut();
                      } else {
                        this.$alert(
                          "Error " + "No se pudo realizar el registro"
                        );
                      }
                    }
                  });
                })
                .catch(error => {
                  this.$alert("Error :" + error.message);
                });
            })
            .catch(error => {
              var add =
                error.message == "Password should be at least 6 characters"
                  ? "La contraseña debe tener al menos 8 caracteres"
                  : error.message ==
                    "The email address is already in use by another account."
                  ? "Esté correo ya fue registrado"
                  : error.message == "The email address is badly formatted."
                  ? "Formato de email invalido"
                  : error.message;
              this.$alert("Error : " + add);
            });
        } else if (this.opcion == "Ingresar") {
          firebase
            .auth()
            .signInWithEmailAndPassword(this.user.email, this.user.password)
            .then(() => {
              firebase
                .auth()
                .currentUser.getIdToken(/* forceRefresh */ true)
                .then(idToken => {
                  // console.log(idToken);
                  this.validaUsuario(idToken);
                })
                .catch(err => {
                  this.$alert(err);
                });
            })
            .catch(err => {
              var add =
                err.message ==
                "The password is invalid or the user does not have a password."
                  ? "La contraseña es invalida o el usuario no tiene contraseña"
                  : err.message == "The email address is badly formatted."
                  ? "formato de correo invalido"
                  : err.message ==
                    "The user account has been disabled by an administrator."
                  ? "La cuenta ha sido deshabilitada por un administrador"
                  : err.message ==
                    "There is no user record corresponding to this identifier. The user may have been deleted."
                  ? "Usuario no registrado"
                  : err.message;
              this.$alert("Error : " + add);
            });
        }
      } else {
        this.$alert("Debe llenar todos los campos");
      }
    },
    validaUsuario(idToken) {
      const fd = new FormData();
      fd.append("token", idToken);
      axios.post(host + "/infoUsuario", fd).then(result => {
        let data = result.data;
        this.$store.state.adminLevel = data.isAdmin;
        if (data.text == "Error, dirección IP bloqueada") {
          this.$alert("IP bloqueada por el administrador");
          firebase.auth().signOut();
        } else {
          if (this.$store.state.adminLevel == 2) {
            router.push("../novedadesDoc");
          } else {
            router.push("../novedadesAdmin");
          }
        }
      });
    },

    change() {
      if (this.opcion == "Ingresar") {
        this.opcion = "Registrar";
        this.entrar = "Registro";
      } else {
        this.opcion = "Ingresar";
        this.entrar = "Entrar";
      }
    },
    forgotPass() {
      this.forgot = true;
    },
    sendEmail() {
      if (!this.user.email) {
        this.$alert("Formato de correo invalido");
      }
      this.error = null;
      this.emailSending = true;
      firebase
        .auth()
        .sendPasswordResetEmail(this.user.email)
        .then(() => {
          this.emailSending = false;
          this.$alert("Correo enviado satisfactoriamente");
          this.forgot = false;
        })
        .catch(error => {
          this.emailSending = false;
          var err =
            error.message == "The email address is badly formatted."
              ? "Formato invalido para el correo"
              : error.message ==
                "There is no user record corresponding to this identifier. The user may have been deleted."
              ? "Correo no registrado o eliminado"
              : error.message;
          this.$alert("Correo NO enviado, intente nuevamente, Error: " + err);
        });
    }
  }
};
</script>
<style>
.m {
  text-transform: none;
  font-size: 15px;
}

.l {
  font-size: 20px;
}
</style>
