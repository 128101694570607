import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify/lib';
import firebase from 'firebase';
import router from '../src/components/Tools/rutas';
import VueSimpleAlert from "vue-simple-alert";
import 'sweetalert2/dist/sweetalert2.min.css';
import VueResource from 'vue-resource';
import VueExcelEditor from 'vue-excel-editor'
import VueMoment from 'vue-moment'
import Vue2Filters from 'vue2-filters'
import moment from 'moment';
import { VueMaskDirective } from 'v-mask'
import numFormat from 'vue-filter-number-format';
import numeral from 'numeral';
import VuePhoneNumberInput from 'vue-phone-number-input';
import VueConfirmDialog from 'vue-confirm-dialog';
import money from 'v-money'
import VueSimpleContextMenu from "vue-simple-context-menu";
import "vue-simple-context-menu/dist/vue-simple-context-menu.css";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import store from './store'



Vue.use(VueConfirmDialog)
Vue.use(money, {precision: 4})
Vue.use(Vue2Filters)
Vue.use(VueMoment)
Vue.use(VueExcelEditor)
Vue.use(VueResource);
Vue.use(VueSimpleAlert, { confirmButtonColor:  '#0D47A1',});
Vue.use(Vuetify)

Vue.component("vue-simple-context-menu", VueSimpleContextMenu);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

Vue.directive('mask', VueMaskDirective);
window.adminLevel = null

Vue.filter('numFormat', numFormat(numeral));
Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
    }
});

Vue.config.productionTip = false
const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0D47A1',
        secondary: '#1565C0',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
    },
  },
})
const config = {
    apiKey: "AIzaSyAORhBhIjLcmZ0DjQVPRi0rl5hy5qxZGr4",
    authDomain: "aseu-323516.firebaseapp.com",
    projectId: "aseu-323516",
    storageBucket: "aseu-323516.appspot.com",
    messagingSenderId: "682735617592",
    appId: "1:682735617592:web:4e068f7d7b78c58ed8c8cd"
};

let app = '';
firebase.initializeApp(config);
firebase.auth().onAuthStateChanged(() =>{
  if(!app){
    app = new Vue({
      el: '#app',
      vuetify,
      render: h => h(App),
      router,
      store
    }).$mount('#app')
    
  }
})
