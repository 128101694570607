import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


const store = new Vuex.Store({
    state: {
        adminLevel: null, 
        dataUser: [], 
        
        ceads : [],
        carreras: [],
        lineas: [],
        servidores: [], 
        agentes: [],
        modosPago: [],
        moodles:[],
        mediosVenta: [],
        estadoU: [], 
        estadosPagos: [],
        averiados: [],
        respuestas: [],
        listaNegra: [],
        notasTrabajos: [],  // Solo ASEU
        listaNegraTrabajos: [], // Solo ASEU
        tipoG: [],
        estadoG: [],
        // listINFO
        alertas: [],
        respuesta: [],
        periodos: [],
        cursos: [], // /cursosBase
        materias:[], // tiene toda la info (curso base, periodo y demas) /cursos


        paises: [],
        tiposAlerta:[],
        // respuesta:[],
        mostrarA: false,

        fechasPagos: null,
        estudiantesFiltro: null,
        clientesFiltro: null,


        clientesHeigth: 20, 
        clientesAHeigth: 20, 
        clientesRHeigth: 20, 
        estudiantesHeigth: 20, 
        gestionHeigth: 20, 
        gestionDHeigth: 20, 
        pagosDHeigth: 20, 
        pagosTDHeigth: 20, 
        trabajosHeigth: 20,
        trabajosDHeigth: 20,
        usuariosHeigth: 20,
        

    },
    mutations: {
        reduce(state, playload = {}) {
            state[playload.name].splice(playload.edited, 1);
        }, 
        add(state, playload = {}) {
            state[playload.name].push(playload.data);
        }
    }
})





export default store