import firebase from 'firebase'
import { host} from '../Tools/variables'
import axios from 'axios'



function filtro(){
    return new Promise(resolve =>{
        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
            const fd = new FormData();
            fd.append('token', idToken);
            fd.append('tipo', document.title);
            fd.append('opcion', "listar");
                axios.post(host+ '/filtros', fd).then((result) => {
                    if(result.data.text != "OK") {
                        this.$alert('fue imposible guardar esta personalizacion del filtro')
                    }else{
                        var data = result.data.lista
                        var devolver = [];
                        for (let u = 0; u < data.length; u++) {
                            const element = data[u];
                            if(element['nombre'] != "CBlomejor"){
                                devolver.push(element)
                            }
                        }
                        resolve(devolver)
                    }
                })              
            }).catch(function(error) {
                console.log(error)
                this.$alert('Error '  +  error.message)
        }) 
    })
    
}

function setFilters(grid){
    return new Promise(resolve => {
        var data =[]
        filtro().then(rta => {
            for (let f = 0; f < rta.length; f++) {
                const element = rta[f];
                for (let ele = 0; ele < grid.length; ele++) {
                    const el = grid[ele]['name'];
                    if (el == element['nombre']) {
                        data.push(element)
                    }
                }
            }
            resolve(data);
        })
    })
}


export {filtro , setFilters}