
import axios from "axios";
import {setFilters} from "../components/Tools/filtros.js";
import firebase from 'firebase'
import { host} from '../components/Tools/variables'



const tablesMixin = {
    data() {
        return {
            // pageLength: 0,
            infoColorBack: [],
            idsColor: [],
            enterAbajo: false,
            //Row con filtro y todo
            rowFilter: null,
            selectedRow: null,
            myLabels: {
            footerLeft: (arriba, abajo, total) =>
                `Del ${arriba} a ${abajo} de ${total}`,
            first: "Pimera",
            previous: "Previa",
            next: "Siguiente",
            last: "Anterior",
            footerRight: {
                selected: "Seleccionados:",
                filtered: "Filtrados:",
                loaded: "Cargados:"
                },
            near: "≒ cercano",
            exactMatch: "= igual a",
            notMatch: "≠ diferente a",
            greaterThan: "&gt; mayor quen",
            greaterThanOrEqualTo: "≥ mayor e igual que",
            lessThan: "&lt; menor que",
            lessThanOrEqualTo: "≤ menor o igual que",
            regularExpression: "~ expersiones regurales",
            },
        }
    },

    methods: {
        

        position(rowPos) {
            this.selectedRow = rowPos.rowPos;
        },
        
        valid(content, oldContent) {
            if (content != oldContent) return 'Este campo no se puede editar'
                return '' // return empty string if there is no error
        },
        
        getLabelColor(pos) {
            this.asignarColor.name = this.historial.nombreCol = pos.cell.id.split("-")[pos.cell.id.split("-").length - 1];
            this.asignarColor.idItem = this.historial.id = pos.record._id
        },
        //  cargue col de ClientesAdmin
        async cargueColCA() {
            if (this.adminLevel != 1) {
                this.$refs.grid1.fields.forEach(field => {
                const l = this.listaColumnas;
                for (var k = 0, len = l.length; k < len; k++) {
                    if (field.name == "Agregar ventas") {
                    field.invisible = false;
                    } else {
                        if (field.name == l[k]["nombre"]) {
                            field.invisible = !l[k]["valor"];
                        }
                    }
                }
            });
            this.getFilterCA('grid1');
            }
                else {
                    this.$refs.grid.fields.forEach(field => {
                    const li = this.listaColumnas;
                    for (var k = 0, leng = li.length; k < leng; k++) {
                        if (field.name == li[k]["nombre"]) {
                            field.invisible = !li[k]["valor"];
                        }
                    }
                });
                this.getFilterCA('grid');
            }
        },

        async getFilterCA(d){
            if(d == 'grid'){
                await setFilters(this.$refs.grid.fields).then((rta)=>{
                for (let f = 0, len = rta.length; f < len; f++) {
                    const element = rta[f];
                    this.$refs.grid.setFilter(element['nombre'], element['value'])
                    }
                })
            }else{
                await setFilters(this.$refs.grid1.fields).then((rta)=>{
                for (let f = 0, len = rta.length; f < len; f++) {
                    const element = rta[f];
                    this.$refs.grid1.setFilter(element['nombre'], element['value'])
                    }
                })
            }
        },

        async cargueCol(lista){
            this.$refs.grid.fields.forEach(field => {
                for (var k = 0; k < this.listaColumnas.length; k++) {
                    if(lista != undefined){
                    for (let i = 0; i < lista.length; i++) {
                        const element = lista[i];
                        if (field.name == element) {
                            field.invisible = false;
                        } 
                    }
                }
                    if(field.name == this.listaColumnas[k]["nombre"]) {
                        field.invisible = !this.listaColumnas[k]["valor"];
                    }
                }
            });
        this.getFilter();
        },

        async getFilter(){
            await setFilters(this.$refs.grid.fields).then((rta)=>{
                for (let f = 0, len = rta.length; f < len; f++) {
                    const element = rta[f];
                    this.$refs.grid.setFilter(element['nombre'], element['value'])
                }
            })
        },
        
        async cargueColors() {
            this.mt = false;
            return new Promise(resolve => {
                var data = '';
                // if (document.title.includes('/')) {
                //     var srt = document.title.split('/')
                //     data = srt[0]
                // }
                // else {
                    data = document.title
                // }
                firebase.auth().currentUser.getIdToken(true).then((idToken) => {
                    const fd = new FormData();
                    fd.append('token', idToken)
                    fd.append('tipo', 'consultar')
                    fd.append('nombreTabla', data)
                    axios.post(host + '/colores', fd).then((rta) => {
                        this.mt = true;
                        this.infoColorBack = rta.data.lista
                        this.idsColor = []
                        for (let f = 0; f < this.infoColorBack.length; f++) {
                            const element = this.infoColorBack[f];
                            this.idsColor.push(element.id)
                        }
                        resolve(true);
                    })
                }).catch(function (error) {
                    this.$alert('Error ' + error.message)
                    resolve(false);
                })
            })
        },

         async asignacionColor(row, element, tipo) {
            if (element.id == row[tipo]) {
                return element.color;
            }
            else {
                return null;
            }
        },

        metodoColorBack(row, col) {
            if (this.infoColorBack.length == 0) {
                return this.haveCellsColor ? this.metodoColor(row, col) : {};
            } else {
                if (this.idsColor.includes(row["_id"])) {
                var ind = this.idsColor.indexOf(row["_id"]);
                if (this.infoColorBack[ind] === "undefined") {
                    return {};
                } else {
                    for (let d = 0; d < this.infoColorBack.length; d++) {
                    const element = this.infoColorBack[d];
                    if (col.name == element["nombreCol"] && row['_id'] == element['id']) {
                        return {
                        "background-color": element["color"],
                        };
                    } 
                    }
                }
                return this.haveCellsColor ? this.metodoColor(row, col) : {};
                }
            }
            return this.haveCellsColor ? this.metodoColor(row, col) : {};
        },

        // EVENTOS
        toolsMenu(event) {
             if (event.which === 3) {
                this.$emit('toggle-table', true)
             } else {
                 this.$emit('toggle-table', false)
            }
        },

        activeRigthClick(a) {
            this.rigthClick = a;  
            
        },

        async eventosColor(color, asignarColor) {
            this.asignarColor.color = color
            var data = '';
            // if (document.title.includes('/')) {
            //     var srt = document.title.split('/')
            //     data = srt[0]
            // }
            // else {
                data = document.title 
            // }
            console.log('data', data)
            firebase.auth().currentUser.getIdToken(true).then((idToken) => {
                const fd = new FormData();
                fd.append('token', idToken)
                fd.append('tipo', 'agregar')
                fd.append('nombreTabla', data)
                fd.append('nombreCol', asignarColor.name)
                fd.append('id', asignarColor.idItem)
                fd.append('color', asignarColor.color)
                axios.post(host + '/colores', fd).then((rta) => {
                    if (rta.data.text == 'OK') {
                        this.cargueColors().then((a) => {
                            this.mt = a;
                      
                        })
                    }
                    else {
                        this.$alert('Error al almacenar el color de este item')
                        
                    }
                })
            })
        },  
        
        eventosTextTool(a) {
            if(a == 'Exportando...'){
                this.textoActionToolBar.push(a)
            }else{
                var rem = this.textoActionToolBar.indexOf('Exportando...')
                this.textoActionToolBar.splice(rem, 1)
            }
            if(a == 'Exportado!'){
                this.textoActionToolBar.push(a)
            }
        },
    }
}

export default tablesMixin