import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase'
import LoginPage from '../../components/LoginPage.vue'

const InitialPage = () => import(/* webpackChunkName: "Usuarios"  */'../../components/InitialPage.vue');
const Periodos = () => import(/* webpackChunkName: "Periodos" */'@/components/Periodos.vue');
const Usuarios = () => import(/* webpackChunkName: "Usuarios"  */'@/components/Usuarios.vue');
const Dashboard = () => import(/* webpackChunkName: "Dashboard"  */'@/components/DashBoard.vue');
const Clientes = () => import(/* webpackChunkName: "Clientes"  */ '@/components/Clientes.vue');
const ClientesRoot = () => import(/* webpackChunkName: "ClientesRoot"  */ '@/components/ClientesRoot.vue');
const ClientesAdmin = () => import(/* webpackChunkName: "ClientesAdmin"  */ '@/components/ClientesAdmin.vue');
const CursosRoot = () => import(/* webpackChunkName: "CursosRoot"  */ '@/components/CursosRoot.vue');
const Listas = () => import(/* webpackChunkName: "Listas"  */ '@/components/Listas.vue');
const Pagos = () => import(/* webpackChunkName: "Pagos"  */ '@/components/Pagos.vue');
const PagosTrabajos = () => import(/* webpackChunkName: "PagosTrabajos"  */ '@/components/PagosTrabajos.vue');
const Alertas = () => import(/* webpackChunkName:"Alertas"  */  '@/components/Alertas.vue');
const Estudiantes = () => import(/* webpackChunkName: "Estudiantes"  */  '@/components/Estudiantes.vue');
const NovedadesD = () => import(/* webpackChunkName: "NovedadesD"  */ '@/components/NovedadesDoc.vue');
const NovedadesA = () => import(/* webpackChunkName:"NovedadesA"  */  '@/components/NovedadesAdmin.vue');
const CajaNegra = () => import(/* webpackChunkName: "CajaNegra"  */ '@/components/CajaNegra.vue');
const Gestion = () => import(/* webpackChunkName: "Gestion"  */  '@/components/GestionPage.vue');
const GestionDoc = () => import(/* webpackChunkName: "GestionDoc"  */ '@/components/GestionPageDoc.vue');
const TrabajosAdmin = () => import(/* webpackChunkName: "TrabajosAdmin"  */ '@/components/TrabajosAdmin.vue');
const TrabajosDoc = () => import(/* webpackChunkName: "TrabajosDoc"  */ '@/components/TrabajosDocente.vue');
const Resumen = () => import(/* webpackChunkName: "Resumen"  */ '@/components/ResumenPage.vue');

import {  host } from './variables'
import axios from 'axios'
import store from '../../store'

Vue.use(Router)



const router = new Router({ 
    routes : [
        {
            path: '/',
            name: 'LoginPage',
            component: LoginPage,
            meta: {
                requiresUser: [0,1,2,-1,-2,-3],

            },
        },
        {
            path: '/initialpage',
            name: 'InitialPage',
            component: InitialPage,
            meta: {
                requiresAuth : true,
                requiresUser: [0,1,2,-1,-2,-3],

            },
            children: [
                {
                    path: '/dashboard',
                    name: 'Dashboard',
                    component: Dashboard,
                    meta:  {
                        requiresAuth : true,
                        requiresUser: [0,1,2,-1,-2,-3],
                    }
                },
                {
                    path: '/periodos',
                    name: 'Periodos',
                    component: Periodos,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [0,1,2,-1,-2,-3],

                    }
                },
                {
                    path: '/usuarios',
                    name: 'Usuarios',
                    component: Usuarios,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [0,1,-1,-2,-3],

                    }
                },
                {
                    path: '/clientes',
                    name: 'Clientes',
                    component: Clientes,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [2],

                    }
                },
                
                    {
                        path: '/clientesAdmin',
                        name: 'ClientesAdmin',
                        component: ClientesAdmin,
                        meta: {
                            requiresAuth : true,
                            requiresUser: [0,-1,-2,-3],

                        }
                    },
                    {
                        path: '/be0da683-13f4-49d9-a05c-228befeac513',
                        name: 'ClientesAdmin',
                        component: ClientesAdmin,
                        meta: {
                            requiresAuth : true,
                            requiresUser: [1, 0, -1, -2, -3],

                        }
                    },
                {
                    path: '/clientesRoot',
                    name: 'ClientesRoot',
                    component: ClientesRoot,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [0,-1,-2,-3],

                    }
                },
                // {
                //     path: '/cursos',
                //     name: 'Cursos',
                //     component: Cursos,
                //     meta: {
                //         requiresAuth : true,
                //         requiresUser: [0,1,2,-1,-2,-3],

                //     }
                // },
                {
                    path: '/cursosRoot',
                    name: 'CursosRoot',
                    component: CursosRoot,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [0,1,2,-1,-2,-3],

                    }
                },
                {
                    path: '/listas',
                    name: 'Listas',
                    component: Listas,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [0,1,-1,-2,-3],
                    }
                },
                {
                    path: '/pagos',
                    name: 'Pagos',
                    component: Pagos,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [0,-1,-2,-3],
                    }
                },
                {
                    path: '/pagostrabajos',
                    name: 'pagostrabajos',
                    component: PagosTrabajos,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [0,-1,-2,-3],
                    }
                },
                {
                    path: '/alertas',
                    name: 'Alertas',
                    component: Alertas,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [0,1,2,-1,-2,-3],
                    }
                },
                {
                    path: '/estudiantes',
                    name: 'Estudiantes',
                    component: Estudiantes,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [0,1,-1,-2,-3],
                    }
                },
                {
                    path: '/gestion',
                    name: 'Gestion',
                    component: Gestion,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [0,1,2,-1,-2,-3],
                    }
                },
                {
                    path: '/gestionDoc',
                    name: 'GestionDoc',
                    component: GestionDoc,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [2],
                    }
                },
                {
                    path: '/novedadesDoc',
                    name: 'novedanesDoc',
                    component: NovedadesD,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [2],
                    }
                },
                {
                    path: '/novedadesAdmin',
                    name: 'novedanesAdmin',
                    component: NovedadesA,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [0,1,-1,-2,-3],
                    }
                },
                {
                    path: '/cajaNegra',
                    name: 'cajaNegra',
                    component: CajaNegra,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [0,1,2,-1,-2,-3],
                    }
                },
                {
                    path: '/trabajosAdmin',
                    name: 'trabajosAdmin',
                    component: TrabajosAdmin,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [0,1,-1,-2,-3],
                    }
                },
                {
                    path: '/trabajosDoc',
                    name: 'trabajosDoc',
                    component: TrabajosDoc,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [2],
                    }
                },
                {
                    path: '/resumen',
                    name: 'resumen',
                    component: Resumen,
                    meta: {
                        requiresAuth : true,
                        requiresUser: [0,1,2,-1,-2,-3],
                    }
                }

                
            ]   
        // ...other sub routes
        //    ]
        },
        
    ] 
});



router.beforeEach((to, from, next) =>{
    const currentUser = firebase.auth().currentUser;
    if(currentUser){
        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
            const fd = new FormData();
            fd.append('token', idToken);
               axios.post(host+ '/infoUsuario', fd).then((result) => {
                    let data = result.data;
                    store.state.adminLevel = data.isAdmin;    
                    const info = store.state.adminLevel
                    var siP = false
                    for(var i =0 ; i < to.meta.requiresUser.length ; i ++){
                            if(info == to.meta.requiresUser[i]){ 
                                siP = true;       
                        }
                    } 
                    if(siP){
                         next()
                    } else{
                        next('/')
                        firebase.auth().signOut()
                    }
                })              
            }).catch(function(error) {
                this.$alert('Error '  +  error.message)
        }) 
    } else{
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
        if(requiresAuth && !currentUser) next('/');
        else if (!requiresAuth && currentUser) next('../initialpage');
        else next()
    }
});
export default router

